import EconActivity from "./econActivity";

const Business = class {
    constructor(object) {
        this.contribuitorTypes = [
            {name: 'Emprendedor', value: 'Contribuyente Régimen RIMPE'},
            {name: 'Negocio Popular', value: 'Contribuyente Régimen RIMPE - Negocio popular'},
            {name: 'General', value: 'Contribuyente Régimen GENERAL'}
        ]
        
        if (object === undefined) {
            this.businessType = null;
            this.registeredName = null;
            this.identification = null;
            this.hasAccounting = null;
            this.makesRetention = null;
            this.certPass = null;
            this.certName = "Seleccionar";
            this.contribuitorCode = null;
            this.contribuitorType = null;
            this.rentPercentage= null;
            this.ivaPercentage = null;
            this.defaultActivity =  null;
            this.tax = 0.0;
            this.taxPercentage = '';
            this.expiresAt = null;
            this.defaults = {};
        } else {
            this.businessType = object.businessType || null;
            this.registeredName = object.registeredName || null;
            this.identification = object.identification || null;
            this.hasAccounting = object.hasAccounting || null;
            this.makesRetention = object.makesRetention || null;
            this.certPass = object.certPass || null;
            this.certName = (object.certName !== undefined) ? object.certName : "Seleccionar";
            this.contribuitorCode = object.contribuitorCode || null;
            this.contribuitorType = null;
            for (var i=0; i<this.contribuitorTypes.length; i++) {
                if (object.contribuitorType===this.contribuitorTypes[i].value) {
                    this.contribuitorType = this.contribuitorTypes[i]
                } 
            }
            this.tax = object.tax || 0.12;
            this.taxPercentage = object.taxPercentage || '12';
            this.defaultActivity =  new EconActivity(object.defaultActivity);
            this.defaults = object.defaults || {};

            if (object.expiresAt !== undefined) { 
                const expiresAt = new Date(object.expiresAt);
                const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long',};
                const expiresAtStr = expiresAt.toLocaleDateString('es-EC', options);
                this.expiresAt = expiresAtStr.charAt(0).toUpperCase() + expiresAtStr.slice(1);
            } else { this.expiresAt = null }
        }
    }

}

export default Business